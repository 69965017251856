<template>
  <div>
    <div class="md:hidden">
      <div v-for="category in displayedCategories" :key="category.key">
        <div class="relative mt-12 mb-6">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-teal-700"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="px-4 bg-gray-200 uppercase text-xl text-gray-800 font-toriya">
              {{ category.title }}
            </span>
          </div>
        </div>
        <div class="grid gap-6">
          <div
            v-for="product in filteredProducts(category.key)"
            :key="product.id"
            class="border rounded overflow-hidden shadow-sm hover:shadow-lg bg-gray-100 relative pb-12"
            >
            <div class="relative">
              <img :src="product.picture_url" :alt="product.title" v-if="product.picture_url" class="w-full lg:inset-0">
              <div class="absolute right-0 top-0 pt-4 mr-3 hiragana" v-if="product.title_jp">
                {{ product.title_jp }}
              </div>
            </div>
            <div class="px-6 py-4">
              <div class="flex justify-between items-start text-xl mb-2 font-toriya">
                <div class="font-bold text-gray-800 leading-tight uppercase mr-6">
                  {{ product.title }}
                </div>
                <div class="font-bold text-gray-700 leading-tight">
                  {{ product.price | amount }}
                </div>
              </div>
              <div class="text-sm text-verdigris-100 -mt-2 mb-2" v-if="product.description">
                {{ product.description }}
              </div>
              <p class="text-gray-600 text-sm uppercase mb-3" v-if="product.ingredients">
                {{ product.ingredients.join(" • ") }}
              </p>
              <div class="absolute bottom-0 right-0 pb-6 pr-6 w-full text-right">
                <button
                  class="bg-verdigris-500 text-white py-2 px-4 rounded outline-none focus:outline-none cursor-not-allowed"
                  disabled>
                  Bientôt disponible
                </button>
                <!--
                <button
                  class="bg-verdigris-500 hover:bg-verdigris-700 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none"
                  @click="orderProduct(product)">
                  <i class="fas fa-shopping-cart mr-2"></i>
                  Commander
                </button>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block">
      <div class="flex flex-row justify-center mb-12 text-lg font-thin text-gray-800 text-center uppercase">
        <a
          v-for="category in displayedCategories"
          class="cursor-pointer py-2 mx-6 font-toriya"
          :class="{'font-bold border-b-2 border-verdigris-700 text-verdigris-700': (currentCategory == category.key)}"
          @click="currentCategory = category.key"
          :key="category.key">
          {{ category.title }}
        </a>
      </div>
      <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
        <div
          v-for="product in filteredProducts(currentCategory)"
          :key="product.id"
          class="border rounded overflow-hidden shadow-sm hover:shadow-lg bg-gray-100 relative pb-12"
          >
          <div class="relative">
            <img :src="product.picture_url" :alt="product.title" v-if="product.picture_url" class="w-full inset-0">
            <div class="absolute right-0 top-0 pt-4 mr-3 hiragana" v-if="product.title_jp">
              {{ product.title_jp }}
            </div>
          </div>
          <div class="px-6 py-4">
            <div class="flex justify-between items-start text-xl mb-2 font-toriya">
              <div class="font-bold text-gray-800 leading-tight uppercase">
                {{ product.title }}
              </div>
              <div class="font-bold text-gray-700 leading-tight">
                {{ product.price | amount }}
              </div>
            </div>
            <div class="text-sm text-verdigris-100 -mt-2 mb-2" v-if="product.description">
              {{ product.description }}
            </div>
            <p class="text-gray-600 text-sm uppercase mb-3" v-if="product.ingredients">
              {{ product.ingredients.join(" • ") }}
            </p>
            <div class="absolute bottom-0 right-0 pb-6 pr-6 w-full text-right">
              <button
                class="bg-verdigris-500 text-white py-2 px-4 rounded outline-none focus:outline-none cursor-not-allowed"
                disabled>
                Bientôt disponible
              </button>
              <!--
              <button
                class="bg-verdigris-500 hover:bg-verdigris-700 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none"
                @click="orderProduct(product)">
                <i class="fas fa-shopping-cart mr-2"></i>
                Commander
              </button>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal ref="siteModal">
      <div slot="title">
        <div class="font-bold text-gray-800 uppercase">
          Choisissez votre restaurant
        </div>
      </div>
      <div
        v-for="site in sites"
        :key="site.id"
        class="relative rounded-lg border-2 p-6 my-3 cursor-pointer"
        :class="{'bg-teal-100 border-teal-500 shadow-md': isCurrentSite(site)}"
        @click="selectSite(site)"
        >
        <div class="flex items-center">
          <div class="w-6 flex justify-between items-center mr-6">
            <svg class="w-6 h-6" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g :stroke="isCurrentSite(site) ? 'none' : '#111111'" stroke-width="1" fill="none" fill-rule="evenodd">
                <g fill-rule="nonzero">
                  <path d="M31.1442786,171.840796 C5.2779518,146.858262 -5.09578082,109.862896 4.01023318,75.0738981 C13.1162472,40.2848999 40.2848999,13.1162472 75.0738981,4.01023318 C109.862896,-5.09578082 146.858262,5.2779518 171.840796,31.1442786 C209.549474,70.1869539 209.010186,132.247241 170.628714,170.628714 C132.247241,209.010186 70.1869539,209.549474 31.1442786,171.840796 Z" :fill="isCurrentSite(site) ? '#ECC94B' : '#F7FAFC'"></path>
                  <polygon v-if="isCurrentSite(site)" fill="#FFFFF0" points="66.6666667 89.4527363 89.5522388 112.437811 132.338308 69.6517413 146.268657 83.7810945 89.5522388 140.298507 52.7363184 103.482587 66.6666667 89.3532338"></polygon>
                </g>
              </g>
            </svg>
          </div>
          <div class="uppercase leading-none">
            <p class="text-2xl text-gray-800 mb-2">
              Ikimasho
              <span class="font-bold">{{ site.title }}</span>
            </p>
            <p class="text-sm text-gray-700">
              <i class="fas fa-map-marker-alt mr-1 text-teal-800 text-opacity-50"></i>
              {{ site.street }}
            </p>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="flex justify-between items-center">
          <a class="cursor-pointer text-gray-600" @click="$refs.siteModal.close()">
            Annuler
          </a>
          <button class="bg-verdigris-500 hover:bg-verdigris-700 text-white font-bold py-2 px-4 rounded outline-none focus:outline-none" @click="submitSite()">
            Suivant
            <i class="fas fa-arrow-right ml-2"></i>
          </button>
        </div>
      </div>
    </modal>
    <modal ref="deliveryModal" v-if="currentSite">
      <div slot="title">
        <div class="font-bold text-gray-800 uppercase">
          Mode de livraison
        </div>
      </div>
      <button
        class="w-full rounded-lg border-2 p-6 mt-3 text-center bg-gray-200 hover:bg-gray-300 hover:shadow-md cursor-pointer"
        @click="orderProduct(currentProduct, true)">
        <div class="text-2xl font-bold mb-2">
          Click and collect
        </div>
        <div class="text-gray-700">
          Recevez un SMS quand votre commande est
          <br>
          prête à être récupérée au restaurant
        </div>
      </button>
      <a class="block rounded-lg border-2 p-6 my-3 cursor-pointer text-center bg-gray-200">
        <div class="text-red-700 font-semibold mb-1">
          Bientôt disponible
        </div>
        <div class="w-32 mx-auto mb-2 opacity-50">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="57 57 369 61" data-reactid="17"><g data-reactid="18"><path fill="#262626" d="M228.06,81.56c-5.47,0-9.35,4.28-9.35,10.85v24.1h-8.35V74.09h8.25v5.18a11.19,11.19,0,0,1,9.94-5.48h3v7.77ZM204.59,95.3c0-12.65-9-22.11-21.18-22.11A21.83,21.83,0,0,0,161.73,95.3c0,12.64,9.75,22.2,22.47,22.2a22.17,22.17,0,0,0,18.3-9.06L196.44,104a14.78,14.78,0,0,1-12.24,6.17,14.22,14.22,0,0,1-14-12.14h34.41Zm-34.21-3.89c1.49-6.47,6.66-10.85,12.93-10.85s11.43,4.38,12.83,10.85Zm-35.1-18.22a21.12,21.12,0,0,0-15,6.27V58.25h-8.35v58.26h8.25v-5.38a21.45,21.45,0,0,0,15.12,6.37,22.16,22.16,0,1,0,0-44.31Zm-.6,36.85A14.69,14.69,0,1,1,149.3,95.4,14.58,14.58,0,0,1,134.68,110Zm-53.5-.4c8.06,0,14.32-6.18,14.32-15.44V58.25h8.65v58.26H95.6V111a21.24,21.24,0,0,1-15.41,6.47c-12.43,0-22-9.06-22-22.8V58.25H67v36C67,103.56,73,109.64,81.18,109.64Z" data-reactid="19"/><path fill="#5fb709" d="M252.32,58.25h40.87v10H263.36V82.45h29v9.66h-29v14.44h29.83v10H252.32ZM406.06,117.6c12.53,0,19.59-6,19.59-14.24,0-5.87-4.18-10.25-12.93-12.15l-9.25-1.89c-5.37-1-7.06-2-7.06-4,0-2.59,2.59-4.18,7.36-4.18,5.17,0,9,1.39,10,6.17h10.84c-.59-9-7.06-14.94-20.18-14.94-11.34,0-19.3,4.68-19.3,13.75,0,6.27,4.38,10.35,13.83,12.34l10.34,2.39c4.08.8,5.17,1.9,5.17,3.59,0,2.69-3.08,4.38-8.06,4.38-6.26,0-9.84-1.39-11.23-6.17H384.28C385.87,111.63,392.53,117.6,406.06,117.6Zm-24.93-1.09H369.4c-7.36,0-11.44-4.58-11.44-10.36V83.25h-8.25V73.49H358V61.24H368.9V73.49h12.23v9.76H368.9v20.11c0,2.29,1.59,3.39,4.08,3.39h8.15Zm-47-43v3.88a21.16,21.16,0,0,0-13.73-5,22.61,22.61,0,1,0,0,45.21,21.1,21.1,0,0,0,13.73-5v3.89H345v-43Zm-12.83,34.65a13.15,13.15,0,1,1,13-13.14A13,13,0,0,1,321.28,108.14Z" data-reactid="20"/></g></svg>
        </div>
        <div class="text-gray-700">
          Faites livrer vos plats à domicile ou au bureau
        </div>
      </a>
      <a class="block rounded-lg border-2 p-6 my-3 cursor-pointer text-center bg-gray-200 hover:bg-gray-300 hover:shadow-md" v-if="currentSite.deliveroo_url" :href="currentSite.deliveroo_url" target="_blank">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-37 211.1 519.2 174.2" style="width: 200px;" class="mx-auto">
            <path class="st0" fill="#00CCBC" d="M156.5,330.9h11.2l-1.9-8.7v-51.7h-10.7v23.6c-3.2-3.7-7.7-5.9-12.9-5.9c-10.7,0-19,8.9-19,21.8
            c0,13,8.3,21.8,19,21.8c5.3,0,9.9-2.3,13.1-6.2L156.5,330.9L156.5,330.9z M368.1,299.8c2.2,0,4.2,0.6,6,1.9l5.1-11.4
            c-2.4-1.5-5.1-2.1-7.8-2.1c-4.8,0-8.9,2.2-11.4,6.3l-1.1-5.3h-11l1.8,8.2v33.6h10.7v-26.7C361.8,301.5,364.5,299.8,368.1,299.8
            L368.1,299.8L368.1,299.8L368.1,299.8z M399.4,320.6c-6.2,0-10.8-4.1-10.8-10.5c0-6.5,4.6-10.5,10.8-10.5c6.3,0,10.8,4,10.8,10.5
            C410.2,316.4,405.7,320.6,399.4,320.6L399.4,320.6z M133.6,310c0-6.5,4.6-10.5,10.8-10.5c6.3,0,10.8,4,10.8,10.5
            c0,6.4-4.5,10.5-10.8,10.5C138.2,320.6,133.6,316.4,133.6,310z M446.9,320.6c-6.2,0-10.8-4.1-10.8-10.5c0-6.5,4.6-10.5,10.8-10.5
            c6.3,0,10.8,4,10.8,10.5C457.6,316.4,453.1,320.6,446.9,320.6z M193.7,298.9c5.5,0,9.1,2.6,10.3,7.4h-20.6
            C184.5,301.4,188.2,298.9,193.7,298.9L193.7,298.9z M321.6,298.9c5.5,0,9.1,2.6,10.3,7.4h-20.6C312.6,301.4,316.2,298.9,321.6,298.9
            L321.6,298.9z M269.4,330.9h17.2l11.2-41.9h-11.7L278,323l-8.2-33.9h-11.5L269.4,330.9L269.4,330.9z M241.9,330.9h10.7V289h-10.7
            V330.9z M195.3,331.7c6,0,11.8-1.7,16.7-4.8l-4.1-9.2c-3.8,2.1-8.2,3.2-12.5,3.2c-5.6,0-9.5-2.2-11.3-6.3h30.2
            c0.3-1.6,0.5-3,0.5-4.9c0-13-9-21.6-20.9-21.6c-12.1,0-20.9,8.8-20.9,21.8C172.9,323.2,181.7,331.7,195.3,331.7L195.3,331.7z
            M468,310c0-13-9-21.8-21.1-21.8c-12.2,0-21.1,8.9-21.1,21.8s9,21.8,21.1,21.8S468,323,468,310L468,310z M323.1,331.8
            c6,0,11.8-1.6,16.7-4.8l-4.1-9.2c-3.8,2.1-8.2,3.2-12.5,3.2c-5.6,0-9.5-2.2-11.3-6.3h30.2c0.3-1.6,0.5-3,0.5-4.9
            c0-13-9-21.6-20.9-21.6c-12.1,0-20.9,8.8-20.9,21.8C300.8,323.4,309.5,331.8,323.1,331.8L323.1,331.8L323.1,331.8z M399.4,331.8
            c12.2,0,21.1-8.9,21.1-21.8s-9-21.8-21.1-21.8c-12.2,0-21.1,8.9-21.1,21.8S387.2,331.8,399.4,331.8L399.4,331.8z M221.6,330.9h10.7
            v-60.4h-10.7V330.9L221.6,330.9z M247.3,283.3c3.7,0,6.7-3,6.7-6.8s-2.9-6.8-6.7-6.8c-3.8,0-6.8,3-6.8,6.8S243.4,283.3,247.3,283.3
            L247.3,283.3z M63.4,232.4l-6.1,58l-10.4-48.9l-32.8,6.9l10.3,48.9l-47.2,10l8.4,39L68.7,364l19-42.5l9-85.6L63.4,232.4L63.4,232.4
            L63.4,232.4z M37.1,316.6c-1.7,1.6-3.9,1.4-6.4,0.6c-2.4-0.8-3.4-3.6-2.6-7.2c0.7-2.6,3.8-3,5.5-3c0.6,0,1.2,0.1,1.8,0.4
            c1.1,0.5,3,1.6,3.4,3.2C39.4,313,38.8,315,37.1,316.6L37.1,316.6L37.1,316.6L37.1,316.6z M61.2,319.2c-1.3,2.2-4.5,2.5-7.8,0.9
            c-2.2-1.1-2.2-3.7-1.9-5.4c0.1-0.9,0.5-1.8,1.1-2.5c0.8-1,2.1-2.3,3.5-2.3c2.5-0.1,4.5,1,5.7,3C63,314.9,62.4,317.1,61.2,319.2
            L61.2,319.2L61.2,319.2z"/>
            </svg>
        </div>
        <div class="text-gray-700">
          Faites livrer vos plats à domicile ou au bureau
        </div>
      </a>
      <div slot="footer" v-if="this.sites.length > 1">
        <div class="flex justify-between items-center">
          <a class="cursor-pointer text-gray-600" @click="$refs.deliveryModal.close(); $refs.siteModal.open()">
            <i class="fas fa-arrow-left mr-2"></i>
            Choix du restaurant
          </a>
        </div>
      </div>
    </modal>
    <modal ref="productModal">
      <div slot="title">
        <div class="flex items-baseline divide-x divide-gray-400 text-xl font-toriya" v-show="currentStep == 0">
          <div class="font-bold text-gray-800 pr-3 uppercase">
            {{ currentProduct.title }}
          </div>
          <div class="text-gray-700 pl-3">
            {{ currentProduct.price | amount }}
          </div>
        </div>
        <div v-show="currentStep == 0">
          <div class="text-xl font-bold text-gray-800 pr-3 mt-3">
            Ajouter des extras&nbsp;?
          </div>
          <div class="text-sm text-gray-700">
            Si vous le souhaitez, vous pouvez ajouter jusqu'à 2 extras de chaque type. Prix indiqués par unité.
          </div>
        </div>
      </div>
      <div v-show="currentStep == 0">
        <div
          class="my-1 p-2 border rounded"
          v-for="extra in filteredProducts('extra')"
          :class="quantityExtra(extra) > 0 ? 'bg-verdigris-100 border-verdigris-500' : 'border-0'"
          :key="extra.id">
          <div class="flex justify-between items-center">
            <div class="flex flex-grow flex-col">
              <div class="flex flex-grow items-baseline divide-x divide-gray-200">
                <div class="font-bold text-gray-800 pr-2 font-toriya uppercase max-w-xs">
                  {{ extra.title }}
                </div>
                <div class="flex-grow text-gray-700 pl-2">
                  {{ extra.price | amount }}
                </div>
              </div>
              <div class="text-gray-600 text-xs md:text-sm" v-if="extra.ingredients">
                {{ extra.ingredients.join(' • ') }}
              </div>
              <div class="text-gray-600 text-xs md:text-sm" v-if="extra.description">
                {{ extra.description }}
              </div>
            </div>
            <div class="w-32 ml-3" v-if="isAvailable(extra)">
              <div class="flex flex-row h-10 w-full rounded-lg bg-transparent">
                <button class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-l cursor-pointer outline-none focus:outline-none" @click="decrementExtra(extra)">
                  <span class="m-auto text-2xl">−</span>
                </button>
                <input
                  type="text"
                  disabled
                  :value="quantityExtra(extra)"
                  :class="quantityExtra(extra) > 0 ? 'text-gray-900' : 'text-gray-600'"
                  class="outline-none focus:outline-none text-center w-full bg-gray-100 font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center">
                <button class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-r cursor-pointer outline-none focus:outline-none" @click="incrementExtra(extra)">
                  <span class="m-auto text-2xl">+</span>
                </button>
              </div>
            </div>
            <div class="w-32 ml-3 text-red-700 text-sm text-center uppercase opacity-75" v-else>
              Produit épuisé
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div v-show="currentStep == 0">
          <div class="flex justify-between items-center">
            <a class="cursor-pointer text-gray-600" @click="currentStep--">
              <i class="fas fa-arrow-left mr-2"></i>
              Précédent
            </a>
            <button
              class="text-white font-bold py-2 px-4 rounded outline-none focus:outline-none bg-verdigris-500 "
              :class="busy ? 'bg-opacity-50 cursor-not-allowed' : 'hover:bg-verdigris-700'"
              :disabled="busy"
              @click="submitProduct()">
              <i class="fas mr-1" :class="busy ? 'fa-spinner fa-pulse' : 'fa-check'"></i>
              Valider
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import axios from 'axios'
  import Modal from '../../../ui/Modal'
  import Amount from '../../../filters/Amount'
  import { OrderBus } from '../../../packs/ikimasho/front'
  export default {
    components: {
      Modal
    },
    filters: {
      Amount
    },
    props: {
      products: Array,
      sites: Array
    },
    data() {
      return {
        order: {},
        busy: false,
        currentSite: null,
        currentCategory: 'plat',
        currentProduct: {},
        currentStep: 0,
        orderItem: { extras: [] },
        displayedCategories: [{
          key: "plat",
          title: "Plats"
        }, {
          key: "side",
          title: "Side"
        }, {
          key: "boisson",
          title: "Boissons"
        }]
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
        this.currentSite = this.sites.find((site) => site.id == this.order.site_id)
      })
    },
    methods: {
      isCurrentSite(site) {
        return this.currentSite && this.currentSite.id === site.id
      },
      isAvailable(product) {
        if (product && product.stocks && this.order.site_id) {
          return product.stocks.find((stock) => stock.site_id == this.order.site_id).available
        } else {
          return true
        }
      },
      submitProduct() {
        if (this.busy) return
        this.busy = true
        axios.post(
          this.order.items_url, {
            order_item: this.orderItem
          }
        ).then((response) => {
          Turbolinks.visit(this.order.url)
        }).catch((error) => {
          console.error(error)
          this.busy = false
        })
      },
      selectSite(site) {
        this.currentSite = site
      },
      submitSite() {
        this.order.site_id = this.currentSite.id
        this.$refs.siteModal.close()
        this.$nextTick(() => this.$refs.deliveryModal.open())
        axios.patch(
          this.order.url, { order: { site_id: this.order.site_id }}
        )
      },
      filteredProducts(category) {
        let result = this.products.filter((product) => {
          return product.category == category
        })
        if (category == 'extra') {
          if (!this.currentProduct.spicy) {
            let index = result.findIndex((e) => e.title == "Piment")
            if (index >= 0) { result.splice(index, 1) }
          }
          if (this.currentProduct.title != "Omoide Shoyu" &&
              this.currentProduct.title != "Iekei' shiitaké" &&
              this.currentProduct.title != "Paitan" &&
              this.currentProduct.title != "Tan-tan") {
            let index = result.findIndex((e) => e.title == "Suprême de poulet")
            if (index >= 0) { result.splice(index, 1) }
            index = result.findIndex((e) => e.title == "Chashu de porc")
            if (index >= 0) { result.splice(index, 1) }
            index = result.findIndex((e) => e.title == "Ajitamago + chashu de porc + suprême de poulet")
            if (index >= 0) { result.splice(index, 1) }
          }
          if (this.currentProduct.title != "Dip'n Slurp") {
            let index = result.findIndex((e) => e.title == "Tofu")
            if (index >= 0) { result.splice(index, 1) }
            index = result.findIndex((e) => e.title == "Ajitamago + Tofu")
            if (index >= 0) { result.splice(index, 1) }
          }
        }
        return result
      },
      orderProduct(product, force = false) {
        this.currentProduct = product
        if (!this.isAvailable(this.currentProduct)) {
          this.currentStep = 0
          this.$refs.deliveryModal.close()
          this.$refs.productModal.open()
        } else {
          if (this.sites.length == 1) {
            this.selectSite(this.sites[0])
            this.order.site_id = this.currentSite.id
          }
          if (!force && this.order.items.length == 0) {
            this.submitSite()
          } else {
            this.currentStep = 0
            this.orderItem = {
              product_id: product.id,
              variant: null,
              spiciness: (product.spicy ? 1 : null),
              extras: []
            }
            this.$refs.deliveryModal.close()
            if (product.category == 'plat' || !this.isAvailable(product)) {
              this.$refs.productModal.open()
            } else {
              this.submitProduct()
            }
          }
        }
      },
      cancelProduct(product) {
        this.currentProduct = {}
        this.currentStep = 0
        this.orderItem = { extras: [] }
        this.$refs.productModal.close()
      },
      quantityExtra(extra) {
        const current = this.orderItem.extras.find((item) => item.product_id == extra.id)
        if (current) {
          return current.quantity
        } else {
          return 0
        }
      },
      decrementExtra(extra) {
        const index = this.orderItem.extras.findIndex((item) => item.product_id == extra.id)
        if (index >= 0) {
          const extra = this.orderItem.extras[index]
          if (extra.quantity == 1) {
            this.orderItem.extras.splice(index, 1)
          } else {
            extra.quantity -= 1
          }
        }
      },
      incrementExtra(extra) {
        let current = this.orderItem.extras.find((item) => item.product_id == extra.id)
        if (current) {
          if (current.quantity < 2) {
            current.quantity += 1
          }
        } else {
          current = {
            product_id: extra.id,
            quantity: 1
          }
          this.orderItem.extras.push(current)
        }
      }
    }
  }
</script>
