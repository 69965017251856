<template>
  <div>
    <p class="text-xl mt-6 font-thin">
      Lieu et date de retrait&nbsp;:
    </p>
    <p class="text-xl font-bold uppercase">
      {{ order.shop_title }} {{ order.site_title }}
      <br>
      {{ order.scheduled_at | time('eeee dd MMM') }} entre {{ order.scheduled_at | time("HH'h'mm") }} et {{ upper_scheduled_at | time("HH'h'mm") }}
    </p>
    <div v-if="!order.managed">
      <div class="mt-2" v-show="mode == 'default'">
        <button class="text-teal-600 p-2 text-center cursor-pointer rounded focus:outline-none" @click="mode = 'pickups'">
          <i class="far fa-clock mr-1"></i>
          Modifier la date de retrait
        </button>
      </div>
      <div class="mt-2" v-show="mode == 'success'">
        <p class="text-teal-600 p-2 text-center">
          <i class="fas fa-check mr-1"></i>
          Votre modification a bien été enregistrée.
        </p>
      </div>
      <div class="flex gap-2 mt-4" v-show="mode == 'pickups'">
        <div class="relative inline-flex w-full">
          <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232">
            <path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/>
          </svg>
          <select ref="scheduled_at" class="w-full border border-gray-300 rounded-full text-gray-700 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none cursor-pointer" v-model="model.scheduled_at">
            <optgroup v-for="pickup in pickups" :label="pickup.day" v-if="pickup.times.length > 0">
              <option v-for="time in pickup.times" :value="time">
                {{ timeOption(time) }}
              </option>
            </optgroup>
          </select>
        </div>
        <button class="border border-gray-300 rounded-full text-gray-700 h-10 px-4 text-sm bg-white hover:border-gray-400" @click="submit()">
          Enregistrer
        </button>
        <button class="border border-gray-300 rounded-full text-gray-700 h-10 px-4 text-sm bg-white hover:border-gray-400" @click="mode = 'default'">
          Annuler
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { parseISO, add, getDay, set, isBefore, formatISO, format } from 'date-fns'
  import Time from '../../../filters/Time'
  import axios from 'axios'

  export default {
    filters: {
      Time
    },
    props: {
      order: Object
    },
    data() {
      return {
        refresh: 0,
        mode: 'default',
        model: {
          scheduled_at: this.order.scheduled_at
        },
        busy: false,
        message: null
      }
    },
    computed: {
      upper_scheduled_at() {
        this.refresh
        return add(parseISO(this.order.scheduled_at), { minutes: 15 })
      },
      pickups() {
        const pickups = [{
          day: "Aujourd'hui", times: [],
        }, {
          day: "Demain", times: []
        }]
        let weekStartTimes, weekEndTimes
        weekStartTimes = [
          { hours: 11, minutes: 30 },
          { hours: 12, minutes: 0 },
          { hours: 12, minutes: 30 },
          { hours: 13, minutes: 0 },
          { hours: 13, minutes: 30 },
          { hours: 14, minutes: 0 },
          { hours: 14, minutes: 30 },
          { hours: 15, minutes: 0 },
          { hours: 15, minutes: 30 },
          { hours: 16, minutes: 0 },
          { hours: 16, minutes: 30 },
          { hours: 17, minutes: 0 },
          { hours: 17, minutes: 30 },
          { hours: 18, minutes: 0 },
          { hours: 18, minutes: 30 },
          { hours: 19, minutes: 0 },
          { hours: 19, minutes: 30 },
          { hours: 20, minutes: 0 },
          { hours: 20, minutes: 30 },
          { hours: 21, minutes: 0 },
          { hours: 21, minutes: 30 }
        ]
        weekEndTimes = [
          { hours: 11, minutes: 30 },
          { hours: 12, minutes: 0 },
          { hours: 12, minutes: 30 },
          { hours: 13, minutes: 0 },
          { hours: 13, minutes: 30 },
          { hours: 14, minutes: 0 },
          { hours: 14, minutes: 30 },
          { hours: 15, minutes: 0 },
          { hours: 15, minutes: 30 },
          { hours: 16, minutes: 0 },
          { hours: 16, minutes: 30 },
          { hours: 17, minutes: 0 },
          { hours: 17, minutes: 30 },
          { hours: 18, minutes: 0 },
          { hours: 18, minutes: 30 },
          { hours: 19, minutes: 0 },
          { hours: 19, minutes: 30 },
          { hours: 20, minutes: 0 },
          { hours: 20, minutes: 30 },
          { hours: 21, minutes: 0 },
          { hours: 21, minutes: 30 }
        ]
        const nowWithOffest = add(new Date(), { minutes: 25 })
        for (let dayOffset = 0; dayOffset < 2; dayOffset++) {
          let date = add(new Date(), { days: dayOffset })
          let day = getDay(date)
          let times
          let fmt
          if (day === 0 || day === 5 || day === 6) {
            times = weekEndTimes
          } else {
            times = weekStartTimes
          }
          times.forEach((time) => {
            date = set(date, { hours: time.hours, minutes: time.minutes, seconds: 0, milliseconds: 0 })
            fmt = format(date, 'MM-dd')
            if (isBefore(nowWithOffest, date) && (fmt != '12-31' || (fmt == '12-31' && time.hours >= 11 && time.hours <= 15)) && (fmt != '12-24' || (fmt == '12-24' && time.hours >= 11 && time.hours <= 14)) && fmt != '12-25') {
              pickups[dayOffset]['times'].push(formatISO(date))
            }
          })
        }
        return pickups
      }
    },
    methods: {
      timeOption(time) {
        let date = parseISO(time)
        return `Entre ${format(date, "HH'h'mm")} et ${format(add(date, { minutes: 15 }), "HH'h'mm")}`
      },
      submit() {
        if (this.busy) return
        this.busy = true
        axios.patch(
          this.order.url, {
            order: this.model
          }
        ).then((response) => {
          this.busy = false
          this.order.scheduled_at = response.data.scheduled_at
          this.refresh++
          this.message = 'Votre modification a bien été prise en compte.'
          this.mode = 'success'
        }).catch((error) => {
          this.busy = false
          console.error(error)
        })
      }
    }
  }
</script>
